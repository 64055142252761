import {
    email,
    required
} from 'vuelidate/lib/validators';

export const validationRules = {
    email: {
        required,
        email,
        async emailExists(value) {
            if (!this.$v.email.required || !this.$v.email.email) {
                return true;
            }

            const response = await this.checkEmail({ email: value })
            return response.success
        },
        async validateEmail(email) {
            const allowedCharacters = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return allowedCharacters.test(String(email).toLowerCase());
        }
    }
}
