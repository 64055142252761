<template>
    <div class="page-section-mask">
        <header-content :header-info="headerInfo" />
        <section class="faq profile grid-container profile-setting-page search-category wrapper">
            <div class="grid__col12">
                <div
                    id="profile-info"
                    class="profile-info__settings profile-info__settings_email"
                >
                    <div>
                        <form @submit.prevent="checkValidation">
                            <div class="profile-info">
                                <slot name="form" />

                                <footer class="profile-info__footer">
                                    <custom-button
                                        v-if="showSaveBtn"
                                        default
                                        :disabled="!saveActive"
                                        class="btn-base btn-main button"
                                        :type="'submit'"
                                    >
                                        <span v-if="friends">
                                            Invite
                                        </span>
                                        <span v-else>
                                            Save
                                        </span>
                                    </custom-button>
                                    <custom-button
                                        class="btn-base btn-border button"
                                        type="reset"
                                        bordered
                                        hover
                                        @on-btn-click="$router.push({ name: path })"
                                    >
                                        <span class="profile-info__reset-button-icon">
                                            Cancel
                                        </span>
                                    </custom-button>
                                </footer>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <!-- <auth-modal
            :show-modal="showModal"
            @close-modal="$emit('close-modal')"
            @confirm="$emit('confirm')"
            @passwordChecked="$emit('confirm')"
        /> -->
    </div>
</template>

<script>
// import AuthModal from '@/components/modals/AuthModal';
import { eventBus } from '@/helpers/event-bus';
import HeaderContent from '@/components/HeaderContent';

export default {
    name: 'ProfileFormLayout',
    components: {
        HeaderContent
    },
    props: {
        showModal: Boolean,
        friends: Boolean,
        title: String,
        saveActive: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            headerInfo: {
                title: this.title,
                className: 'header-content__account-setting',
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    },
                    {
                        title: 'Profile',
                        url: '/cp/profile'
                    }
                ]
            }
        }
    },
    computed: {
        path() {
            const name = this.$route.name === 'new_friend' ? 'friends' : 'profile'
            return name
        },
        showSaveBtn() {
            return this.$route.name !== 'profile_phone'
        }
    },
    methods: {
        checkValidation() {
            eventBus.$emit('saveSettings', true)
        }
    }
}
</script>

<style scoped lang="scss">
.profile-info {
    position: relative;

    &__save-button-icon {
        margin-right: 8px;
        svg {
            width: 15px;
        }
    }
    &__header {
        position: relative;
        &-icon {
            margin-right: 15px;
            color: #ff690c;
        }
        .button {
            background: #fff !important;
            position: absolute;
            left: 0;
            padding: 12px 12px 12px 18px !important;
            svg {
                height: 40px;
                width: 40px;
                color: #ff690c;
            }
        }
    }
    &__footer {
        box-shadow: unset !important;
        .button{
            width: 158px !important;
            padding: 0 !important;
            margin-right: 30px;
            &:nth-child(2) {
                margin-right: 0;
            }
        }
        @media screen and (max-width:576px){
            .button{
                margin-right: 0;
                width: calc(50% - 15px) !important;
            }
        }
    }
}
.profile.wrapper {
    position: relative;
    top: -40px;
    margin-bottom: 30px;
}
.button.bordered_button {
    border: 2px solid #FF690C;
    box-sizing: border-box;
    border-radius: 4px;
    color: #FF690C;
    background: #fff;
}

@media screen and (max-width: 1200px){
    .profile.wrapper {
        top: -40px;
    }
}

@media screen and (max-width: 992px){
    .profile.wrapper {
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 768px){
    .profile.wrapper {
        top: 0px;
    }
}

@media screen and (max-width: 576px) {
    .profile .profile-info__footer .button.btn-base_orange {
        width: calc(100% - 62px);
    }
    .wrapper {
        padding: 0 !important;
    }
    .profile-info {
        &__footer {
            justify-content: space-between !important;
            display: flex;
        }
    }
}

@media screen and (max-width: 480px){
    .profile-info {
        &__header {
            .button {
                padding: 12px 12px 12px 2px !important;
            }
        }
    }
}
</style>
