<template lang="html">
    <main-layout :settings-breadcrumbs="settingsBreadcrumbs">
        <template #content>
            <profile-form-layout
                :show-modal="showModal"
                :title="'Email'"
                @validate="validate"
                @close-modal="showModal=false"
                @confirm="confirm"
            >
                <template #headerIcon>
                    <email-outline />
                </template>
                <template #form>
                    <div class="profile-info__form mt-7 profile-info__form-email">
                        <div class="profile-info__form-group">
                            <div class="profile-info__form-row">
                                <div
                                    class="profile-info__col60 profile-info__col100--sm"
                                    style="margin: auto"
                                >
                                    <form-input
                                        v-model.trim="emailValue"
                                        legend="Email address"
                                        prepend-icon="EmailOutlineIcon"
                                        type="text"
                                        name="email"
                                        :field.sync="emailValue"
                                        :errors="validationMessage($v.email)"
                                        :is-valid="$v.email.$dirty && !$v.email.$anyError"
                                        :loading="loadEmail"
                                        @input.native="$v.email.$touch()"
                                        @blur.native="$v.email.$touch()"
                                        @onChange="loadEmail = true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </profile-form-layout>
        </template>
    </main-layout>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'

import MainLayout from '@/layouts/MainLayout';
import ProfileFormLayout from '@/layouts/profile/ProfileFormLayout';
import EmailOutline from 'mdi-vue/EmailOutline'
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/profile/ProfileEmail/Rules'
import { formMessages } from '@/validation/profile/ProfileEmail/Messages'

import { createNamespacedHelpers } from 'vuex';

import {
    ACTION_WRITER_UPDATE_EMAIL,
    ACTION_WRITER_CHECK_EMAIL,
    ACTION_UPDATE_WRITER_DATA
} from '@/store/modules/writer/action-types';

import {
    UPDATE_WRITER_EMAIL
} from '@/store/modules/writer/mutation-types';

import { debounce } from 'lodash'

import {
    mapWriterFields
} from '@/store/modules/writer';

const {
    mapActions: mapWriterActions,
    mapMutations: mapWriterMutations
} = createNamespacedHelpers('writer');

export default {
    components: {
        MainLayout,
        ProfileFormLayout,
        EmailOutline
    },
    metaInfo: {
        title: 'Email'
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            loadEmail: false,
            emailValue: '',
            email: '',
            showModal: false
        }
    },
    computed: {
        ...mapWriterFields({
            storeEmail: 'writer_data.email'
        })
    },
    watch: {
        emailValue: debounce(function (newEmail) {
            this.email = newEmail.trim()
            this.loadEmail = false
        }, 300)
    },
    created() {
        this.emailValue = this.storeEmail;
    },
    mounted() {
        eventBus.$on('saveSettings', () => {
            this.$v.$reset()
            this.validate()
        })
    },
    beforeDestroy() {
        eventBus.$off('saveSettings')
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapWriterActions({
            ACTION_WRITER_UPDATE_EMAIL,
            ACTION_WRITER_CHECK_EMAIL,
            ACTION_UPDATE_WRITER_DATA
        }),
        ...mapWriterMutations([
            UPDATE_WRITER_EMAIL
        ]),
        async checkEmail(value) {
            const { data } = await this[ACTION_WRITER_CHECK_EMAIL]({ ...value, isSetting: true })
            return data
        },
        async confirm() {
            try {
                const response = await this[ACTION_WRITER_UPDATE_EMAIL]({
                    email: this.email
                })
                if (response) {
                    eventBus.$emit('showSnackBar', 'Your e-mail has been changed', 'success');
                    await this.$router.push({ name: 'profile' })
                    await this[ACTION_UPDATE_WRITER_DATA]()
                }
            } catch (err) {
                console.error(err)
            }
        },
        submit() {
            this.confirm()
            // this.showModal = true
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-info__form-row {
    .profile-info__col50 {
        margin-top: 0 !important;
    }
}

.profile-info__form-email{
    margin-bottom: 30px;
}

@media screen and (max-width: 768px){
    .profile-info__form-row {
        .profile-info__col50:nth-child(2) {
            margin-top: 24px !important;
        }
    }
}
</style>
